import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import ReactLoading from "react-loading";
import { Cell, Row, StickyTable } from "react-sticky-table";
import CenteredModel from "./CenteredModel";

const FatalityRateAgeChart = (props) => {
  const [modalShow, setModalShow] = useState(false);
  let bindData = [];
  const data = {
    labels: [
      "0 - 17  years",
      "18 - 44 years",
      "45 - 64 years old",
      "65 - 74 years old",
      "75+ years old",
    ],
    datasets: [
      {
        label: "Death Rate",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: bindData,
      },
    ],
  };
  const mystyle = {
    backgroundColor: "lightgrey",
    fontWeight: "bold",
  };
  return (
    <div>
      <h2>Fatality Rate By Age</h2>
      {props.values.table
        ? props.values.table.slice(0, 5).map((value, i) => {
            bindData.push(
              parseInt(value.DeathRateConfirmedCases.replace(/,/g, ""))
            );
          })
        : null}
      <div>
        <CenteredModel
          show={modalShow}
          onHide={() => setModalShow(false)}
          data={data}
        >
          <Line data={data} />
        </CenteredModel>
        <div
          style={{
            width: props.isMobile ? "85%" : "23.8%",
            margin: "0 auto",
            border: "2px solid lightgrey",
          }}
        >
          {props.values.table ? (
            <StickyTable stickyFooterCount={1}>
              <Row>
                <Cell style={mystyle}>Age</Cell>
                <Cell style={mystyle}>Deaths</Cell>
                <Cell style={mystyle}>Share of deaths</Cell>
              </Row>
              {props.values.table.map((value, i) => (
                <Row key={i}>
                  <Cell>{value.Age}</Cell>
                  <Cell>{value.DeathRateConfirmedCases}</Cell>
                  <Cell>{value.DeathRateAllCases}</Cell>
                </Row>
              ))}
            </StickyTable>
          ) : (
            <div style={{ display: "inline-block", padding: "30%"}}>
              <ReactLoading type="bars" color="black" />
            </div>
          )}
        </div>
        <Button variant="secondary" onClick={() => setModalShow(true)}>
          Fatality Rate By Age Graph
        </Button>
      </div>
    </div>
  );
};

export default FatalityRateAgeChart;
