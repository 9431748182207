import React, { Component } from "react";
import { StickyTable, Row, Cell } from "react-sticky-table";
import ReactLoading from "react-loading";

function CountryWise(props) {
  // onSort(event, sortKey) {
  //   /*
  //       assuming your data is something like
  //       [
  //         {accountname:'foo', negotiatedcontractvalue:'bar'},
  //         {accountname:'monkey', negotiatedcontractvalue:'spank'},
  //         {accountname:'chicken', negotiatedcontractvalue:'dance'},
  //       ]
  //       */
  //   const data = this.props.values.reports[0].table[0];
  //   data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
  //   this.setState({ data });
  // }

  const mystyle = {
    backgroundColor: "lightgrey",
    fontWeight: "bold",
  };

  return (
    <div>
      <h2>World Statistics</h2>
      <div
        style={{
          width: props.isMobile ? "100%" : "61%",
          height: "510px",
          margin: "0 auto",
          border: "2px solid lightgrey",
        }}
      >
        {props.values.reports ? (
          <StickyTable stickyFooterCount={1}>
            <Row>
              <Cell style={mystyle}>Country</Cell>
              <Cell style={mystyle}>Total Cases</Cell>
              <Cell style={mystyle}>Total Deaths</Cell>
              <Cell style={mystyle}>Total Recovered</Cell>
              <Cell style={mystyle}>Active Cases</Cell>
              <Cell style={mystyle}>New Cases</Cell>
              <Cell style={mystyle}>New Deaths</Cell>
            </Row>
            {props.values.reports[0].table[0].map((p, i) => (
              <Row key={i}>
                <Cell style={{ fontWeight: "bold" }}>{p.Country}</Cell>
                <Cell>{p.TotalCases}</Cell>
                <Cell>{p.TotalDeaths}</Cell>
                <Cell>{p.TotalRecovered}</Cell>
                <Cell>{p.ActiveCases}</Cell>
                <Cell style={{ color: "#007bff" }}>{p.NewCases}</Cell>
                <Cell style={{ color: "#dc3545" }}>{p.NewDeaths}</Cell>
              </Row>
            ))}
          </StickyTable>
        ) : (
          <div style={{ display: "inline-block", padding: "20%" }}>
            <ReactLoading type="bars" color="black" />
          </div>
        )}
      </div>
    </div>
  );
}

export default CountryWise;
