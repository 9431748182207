import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { HashRouter as Router, Link, Route, Switch } from "react-router-dom";
import Chart from "./Chart";
import CountryWise from "./CountryWise";
import Report from "./Report";
import FatalityRateAgeChart from "./FatalityRateAgeChart";
import FatalityRateSexChart from "./FatalityRateSexChart";
import IndianStatisticsMap from "./IndianStatisticsMap";

class CoronaDashboard extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      fatalityRateByAge: [],
      fatalityRateBySex: [],
      indiaData: [],
    };
  }

  fetchData() {
    fetch(process.env.REACT_APP_API)
      .then((res) => res.json())
      .then((values) => {
        this.setState({ data: values });
      })
      .catch(console.log);
  }

  fetchAgeData() {
    fetch(process.env.REACT_APP_RATE_BY_AGE)
      .then((res) => res.json())
      .then((values) => {
        this.setState({ fatalityRateByAge: values });
      })
      .catch(console.log);
  }

  fetchSexData() {
    fetch(process.env.REACT_APP_RATE_BY_SEX)
      .then((res) => res.json())
      .then((values) => {
        this.setState({ fatalityRateBySex: values });
      })
      .catch(console.log);
  }

  fetchIndiaData() {
    fetch(process.env.REACT_APP_INDIA)
      .then((res) => res.json())
      .then((values) => {
        this.setState({ indiaData: values.data[0].table });
      })
      .catch(console.log);
  }

  Styles() {
    const mediaMatch = window.matchMedia("(max-width: 450px)");
    return mediaMatch.matches;
  }

  componentDidMount() {
    this.fetchData();
    this.fetchAgeData();
    this.fetchSexData();
    this.fetchIndiaData();
  }

  render() {
    return (
      <div>
        <Router>
          <Navbar collapseOnSelect variant="light" bg="light" expand="lg">
            <Navbar.Brand>COVID-19 Pandemic Tracker</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link eventKey="1" as={Link} to="/">
                  Home
                </Nav.Link>
                <Nav.Link eventKey="2" as={Link} to="/indiamap">
                  India Statistics
                </Nav.Link>
                <Nav.Link eventKey="3" as={Link} to="/countrywise">
                  World Statistics
                </Nav.Link>
                <Nav.Link eventKey="4" as={Link} to="/fatalitybyage">
                  Fatality Rate by Age
                </Nav.Link>
                <Nav.Link eventKey="5" as={Link} to="/fatalitybysex">
                  Fatality Rate by Gender
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Switch>
            <Route exact path="/">
              <Report values={this.state.data} />
              <Chart isMobile={this.Styles()} values={this.state.data} />
            </Route>
            <Route path="/countrywise">
              <CountryWise isMobile={this.Styles()} values={this.state.data} />
            </Route>
            <Route path="/fatalitybyage">
              <FatalityRateAgeChart
                isMobile={this.Styles()}
                values={this.state.fatalityRateByAge}
              />
            </Route>
            <Route path="/fatalitybysex">
              <FatalityRateSexChart
                isMobile={this.Styles()}
                values={this.state.fatalityRateBySex}
              />
            </Route>
            <Route path="/indiamap">
              <IndianStatisticsMap
                isMobile={this.Styles()}
                values={this.state.indiaData}
              />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default CoronaDashboard;
