import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import ReactLoading from "react-loading";
import { Cell, Row, StickyTable } from "react-sticky-table";
import CenteredModel from "./CenteredModel";

function ChangeColorFunction() {
  let color =
    "rgb(" +
    Math.floor(Math.random() * 256) +
    "," +
    Math.floor(Math.random() * 256) +
    "," +
    Math.floor(Math.random() * 256) +
    ")";
  return color;
}

const FatalityRateSexChart = (props) => {
  const [modalShow, setModalShow] = useState(false);
  let bindData = [];
  const data = {
    labels: ["Male", "Female"],
    datasets: [
      {
        data: bindData,
        backgroundColor: [ChangeColorFunction(), ChangeColorFunction()],
        hoverBackgroundColor: [ChangeColorFunction(), ChangeColorFunction()],
      },
    ],
  };
  const mystyle = {
    backgroundColor: "lightgrey",
    fontWeight: "bold"
  }
  return (
    <div>
      <h2>Fatality Rate By Gender</h2>
      {props.values.table
        ? props.values.table.map((value, i) => {
            bindData.push(
              parseInt(value.DeathRateConfirmedCases.replace(/,/g, ""))
            );
          })
        : null}
      <CenteredModel
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data}
      >
        <Pie data={data} />
      </CenteredModel>
      <div
        style={{
          width: props.isMobile ? "70%" : "19.7%",
          margin: "0 auto",
          border: "2px solid lightgrey",
        }}
      >
        {props.values.table ? (
          <StickyTable>
            <Row>
              <Cell style={mystyle}>Gender</Cell>
              <Cell style={mystyle}>Deaths</Cell>
              <Cell style={mystyle}>Share of deaths</Cell>
            </Row>
            {props.values.table.map((value, i) => (
              <Row key={i}>
                <Cell>{value.Sex}</Cell>
                <Cell>{value.DeathRateConfirmedCases}</Cell>
                <Cell>{value.DeathRateAllCases}</Cell>
              </Row>
            ))}
          </StickyTable>
        ) : (
          <div style={{ display: "inline-block", padding: "10%" }}>
            <ReactLoading type="bars" color="black" />
          </div>
        )}
      </div>
      <Button variant="secondary" onClick={() => setModalShow(true)}>
        Fatality Rate By Gender Graph
      </Button>
    </div>
  );
};

export default FatalityRateSexChart;
