import React, { useState } from "react";
import { scaleQuantile } from "d3-scale";
import ReactTooltip from "react-tooltip";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import LinearGradient from "./MapProperties/LinearGradient.js";
import { Cell, Row, StickyTable } from "react-sticky-table";
import INDIA_TOPO_JSON from "./MapProperties/india.topo.json";
import { Card } from "react-bootstrap";

//const INDIA_TOPO_JSON = require("./ /india.topo.json");

const PROJECTION_CONFIG = {
  scale: 350,
  center: [78.9629, 22.5937],
};

// Red Variants
const COLOR_RANGE = [
  "#ffedea",
  "#ffcec5",
  "#ffad9f",
  "#ff8a75",
  "#ff5533",
  "#e2492d",
  "#be3d26",
  "#9a311f",
  "#782618",
];

const DEFAULT_COLOR = "#EEE";

const getRandomInt = () => {
  return parseInt(Math.random() * 100);
};

const geographyStyle = {
  default: {
    outline: "none",
    stroke:"black"
  },
  hover: {
    fill: "#ccc",
    transition: "all 250ms",
    outline: "none",
  },
  pressed: {
    outline: "none",
  },
};

function IndianStatisticsMap(props) {
  const [tooltipContent, setTooltipContent] = useState("");

  const colorScale = scaleQuantile()
    .domain(props.values.map((d) => d.confirmed))
    .range(COLOR_RANGE);

  const onMouseEnter = (geo, current = { value: "NA" }) => {
    return () => {
      const toolTipText = (
        <ul style={{ "list-style": "none", padding: 0 }}>
          <li>{`${geo.properties.name}`}</li>
          <li>{`Confirmed: ${current.confirmed}`}</li>
          <li>{`Recovered: ${current.recovered}`}</li>
          <li>{`Deceased: ${current.deaths}`}</li>
        </ul>
      );
      setTooltipContent(toolTipText);
    };
  };

  const onMouseLeave = () => {
    setTooltipContent("");
  };

  return (
    <div>
      <h1>Indian Statistics</h1>
      <ReactTooltip>{tooltipContent}</ReactTooltip>
      <ComposableMap
        projectionConfig={PROJECTION_CONFIG}
        projection="geoMercator"
        width={300}
        height={200}
        data-tip=""
      >
        <Geographies geography={INDIA_TOPO_JSON}>
          {({ geographies }) =>
            geographies.map((geo) => {
              //console.log(geo.id);
              const current = props.values.find((s) => s.statecode === geo.id);
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={current ? colorScale(current.confirmed) : DEFAULT_COLOR}
                  style={geographyStyle}
                  onMouseEnter={onMouseEnter(geo, current)}
                  onMouseLeave={onMouseLeave}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
}

export default IndianStatisticsMap;
